<template>
  <div>
    <b-form-group
        label="Região de Atuação"
        label-for="register-cityOperation">
      <validation-provider
          #default="{ errors }"
          name="Região"
          rules="required">
        <b-form-input
            id="register-cityOperation"
            v-model="pfFilesForm.cityOperation"
            name="register-cityOperation"
            :state="errors.length > 0 ? false : null"
            placeholder="Área Metropolitana" />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group label="CRECI" label-for="register-creci">
      <validation-provider #default="{ errors }" name="CRECI" rules="required">
        <b-form-input
            id="register-creci"
            v-model="pfFilesForm.creci"
            name="register-creci"
            :state="errors.length > 0 ? false : null"
            placeholder="" />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      label="Adicione uma foto ou cópia visível do CPF"
      label-for="img_cpf">
      <validation-provider
        #default="{ errors }"
        name="Cópia CPF"
        rules="required">
        <b-form-file
          id="img_cpf"
          ref="img_cpf"
          v-model="pfFilesForm.imgDocument"
          name="img-cpf"
          :state="errors.length > 0 ? false : null"
          :accept="typeFiles"
          placeholder=""
          @change="getBase64($event, 'imgDocument')" />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      label="Adicione uma selfie segurando o CPF"
      label-for="img_self_cpf">
      <validation-provider
        #default="{ errors }"
        name="Self CPF"
        rules="">
        <b-form-file
          id="img_self_cpf"
          ref="img_self_cpf"
          v-model="pfFilesForm.imgFaceDocument"
          name="img-self-cpf"
          :state="errors.length > 0 ? false : null"
          :accept="typeFiles"
          placeholder=""
          @change="getBase64($event, 'imgFaceDocument')" />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      label="Adicione uma foto ou cópia visivel do CRECI"
      label-for="img_creci">
      <validation-provider
        #default="{ errors }"
        name="Cópia CRECI"
        rules="required">
        <b-form-file
          id="img_creci"
          ref="img_creci"
          :accept="typeFiles"
          v-model="pfFilesForm.imgCreci"
          name="img-creci"
          :state="errors.length > 0 ? false : null"
          placeholder=""
          @change="getBase64($event, 'imgCreci')" />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      label="Adicione uma selfie segurando o CRECI"
      label-for="register-img_self_creci">
      <validation-provider
        #default="{ errors }"
        name="Self CRECI"
        rules="">
        <b-form-file
          id="img_self_creci"
          ref="img_self_creci"
          v-model="pfFilesForm.imgFaceCreci"
          name="img-self-creci"
          :state="errors.length > 0 ? false : null"
          :accept="typeFiles"
          placeholder=""
          @change="getBase64($event, 'imgFaceCreci')" />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormInput, BFormGroup, BFormFile } from 'bootstrap-vue'
import dataTypeFiles from '../../../helper/dataTypeFiles'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BFormFile,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
  },
  props: {
    pfFilesForm: {
      type: [String, Array],
    },
  },
  data: () => ({
    typeFiles: dataTypeFiles.types,
    warning: 'Arquivo a ser anexado deve ter até 1MB',
  }),

  watch: {
    pfFilesForm() {
      this.$emit('input', this.value)
    },
  },

  mounted() {},

  methods: {
    async getBase64(event, key) {
      if (this.checkSizeFile(event) !== 1) {
        this.clearFiles(event)
        this.msgWarning()
        return
      }

      const reader = new FileReader()
      // eslint-disable-next-line no-return-assign
      reader.onload = (e => this.pfFilesForm[key] = e.target.result)
      reader.readAsDataURL(event.target.files[0])
    },

    checkSizeFile(event) {
      const file = event.target.files[0]

      if (file.size > 1024 * 1024) {
        return
      }

      // eslint-disable-next-line consistent-return
      return 1
    },

    // warning
    msgWarning() {
      this.$swal({
        icon: 'info',
        title: 'Atenção',
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        html: `${this.warning}
            <br> <br> <br>
            <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
        showCancelButton: false,
        onBeforeOpen: () => {
          const no = document.querySelector('.btn-no')

          no.addEventListener('click', () => {
            this.$swal.close()
          })
        },
      })
    },

    clearFiles(event) {
      this.$refs[event.target.id].reset()
    },
  },

}
</script>
