<template>
  <div>

    <b-form-group label="CRECI" label-for="register-creci">
      <validation-provider #default="{ errors }" name="creci" rules="required">
        <b-form-input
            id="register-creci"
            v-model="pjFilesForm.creci"
            name="register-creci"
            :state="errors.length > 0 ? false : null"
            placeholder="" />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
        label="Cópia do CRECI"
        label-for="imgCreci">
      <validation-provider
          #default="{ errors }"
          name="CRECI"
          rules="required">
        <b-form-file
            id="imgCreci"
            ref="imgCreci"
            v-model="pjFilesForm.imgCreci"
            name="img-creci"
            :state="errors.length > 0 ? false : null"
            :accept="typeFiles"
            placeholder=""
            @change="getBase64($event, 'imgCreci')" />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      label="Cartão CNPJ"
      label-for="imgDocument">
      <validation-provider
        #default="{ errors }"
        name="Cartão CNPJ"
        rules="required">
        <b-form-file
          id="imgDocument"
          ref="imgDocument"
          v-model="pjFilesForm.imgDocument"
          name="img-cnpj"
          :state="errors.length > 0 ? false : null"
          :accept="typeFiles"
          placeholder=""
          @change="getBase64($event, 'imgDocument')" />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormInput, BFormGroup, BFormFile } from 'bootstrap-vue'
import dataTypeFiles from '../../../helper/dataTypeFiles'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BFormFile,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    pjFilesForm: {
      type: Object,
    },
  },

  data: () => ({
    typeFiles: dataTypeFiles.types,
    warning: 'Arquivo a ser anexado deve ter até 1MB',
  }),

  watch: {
    pjFilesForm() {
      this.$emit('input', this.value)
    },
  },

  methods: {
    async getBase64(event, key) {
      if (this.checkSizeFile(event) !== 1) {
        this.clearFiles(event)
        this.msgWarning()
        return
      }
      const reader = new FileReader()
      // eslint-disable-next-line no-return-assign
      reader.onload = (e => this.pjFilesForm[key] = e.target.result)
      reader.readAsDataURL(event.target.files[0])
    },

    checkSizeFile(event) {
      const file = event.target.files[0]

      if (file.size > 1024 * 1024) {
        return
      }

      // eslint-disable-next-line consistent-return
      return 1
    },

    // warning
    msgWarning() {
      this.$swal({
        icon: 'info',
        title: 'Atenção',
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        html: `${this.warning}
            <br> <br> <br>
            <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
        showCancelButton: false,
        onBeforeOpen: () => {
          const no = document.querySelector('.btn-no')

          no.addEventListener('click', () => {
            this.$swal.close()
          })
        },
      })
    },

    clearFiles(event) {
      this.$refs[event.target.id].reset()
    },
  },
}
</script>
