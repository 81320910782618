var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{attrs:{"label":"Região de Atuação","label-for":"register-cityOperation"}},[_c('validation-provider',{attrs:{"name":"Região","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-cityOperation","name":"register-cityOperation","state":errors.length > 0 ? false : null,"placeholder":"Área Metropolitana"},model:{value:(_vm.pfFilesForm.cityOperation),callback:function ($$v) {_vm.$set(_vm.pfFilesForm, "cityOperation", $$v)},expression:"pfFilesForm.cityOperation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"CRECI","label-for":"register-creci"}},[_c('validation-provider',{attrs:{"name":"CRECI","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-creci","name":"register-creci","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.pfFilesForm.creci),callback:function ($$v) {_vm.$set(_vm.pfFilesForm, "creci", $$v)},expression:"pfFilesForm.creci"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Adicione uma foto ou cópia visível do CPF","label-for":"img_cpf"}},[_c('validation-provider',{attrs:{"name":"Cópia CPF","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"img_cpf",attrs:{"id":"img_cpf","name":"img-cpf","state":errors.length > 0 ? false : null,"accept":_vm.typeFiles,"placeholder":""},on:{"change":function($event){return _vm.getBase64($event, 'imgDocument')}},model:{value:(_vm.pfFilesForm.imgDocument),callback:function ($$v) {_vm.$set(_vm.pfFilesForm, "imgDocument", $$v)},expression:"pfFilesForm.imgDocument"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Adicione uma selfie segurando o CPF","label-for":"img_self_cpf"}},[_c('validation-provider',{attrs:{"name":"Self CPF","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"img_self_cpf",attrs:{"id":"img_self_cpf","name":"img-self-cpf","state":errors.length > 0 ? false : null,"accept":_vm.typeFiles,"placeholder":""},on:{"change":function($event){return _vm.getBase64($event, 'imgFaceDocument')}},model:{value:(_vm.pfFilesForm.imgFaceDocument),callback:function ($$v) {_vm.$set(_vm.pfFilesForm, "imgFaceDocument", $$v)},expression:"pfFilesForm.imgFaceDocument"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Adicione uma foto ou cópia visivel do CRECI","label-for":"img_creci"}},[_c('validation-provider',{attrs:{"name":"Cópia CRECI","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"img_creci",attrs:{"id":"img_creci","accept":_vm.typeFiles,"name":"img-creci","state":errors.length > 0 ? false : null,"placeholder":""},on:{"change":function($event){return _vm.getBase64($event, 'imgCreci')}},model:{value:(_vm.pfFilesForm.imgCreci),callback:function ($$v) {_vm.$set(_vm.pfFilesForm, "imgCreci", $$v)},expression:"pfFilesForm.imgCreci"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Adicione uma selfie segurando o CRECI","label-for":"register-img_self_creci"}},[_c('validation-provider',{attrs:{"name":"Self CRECI","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"img_self_creci",attrs:{"id":"img_self_creci","name":"img-self-creci","state":errors.length > 0 ? false : null,"accept":_vm.typeFiles,"placeholder":""},on:{"change":function($event){return _vm.getBase64($event, 'imgFaceCreci')}},model:{value:(_vm.pfFilesForm.imgFaceCreci),callback:function ($$v) {_vm.$set(_vm.pfFilesForm, "imgFaceCreci", $$v)},expression:"pfFilesForm.imgFaceCreci"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }