<template>
  <b-row class="divvy-form__container">
    <Loader v-if="loading"/>
    <b-col class="divvy-form broker">
      <form-wizard
        color="#BC7DF0"
        :title="null"
        :subtitle="null"
        finish-button-text="Abrir conta"
        next-button-text="Avançar"
        back-button-text="Voltar"
        class="vertical-steps mb-3 divvy-form__form"
        @on-complete="submitForm">
        <div class="broker__logo" />

        <tab-content title="" icon="feather" :before-change="validationForm1">
          <h1 class="divvy-form__title">Faça o seu cadastro</h1>
          <validation-observer ref="registerForm1">
            <b-form class="auth-divvy-form mt-2">
              <b-form-group
                label="Corretor ou Imobiliária"
                label-for="register-broker">
                <validation-provider
                  #default="{ errors }"
                  name="register-broker"
                  rules="required">
                  <b-form-select
                    id="register-broker"
                    v-model="register.typePerson"
                    name="register-broker"
                    :state="errors.length > 0 ? false : null"
                    :options="options"
                    @change="changeLabel" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Document -->
              <b-form-group
                v-if="register.typePerson == 'F'"
                :label="labelDocumento"
                label-for="register-document">
                <validation-provider
                  #default="{ errors }"
                  name="CPF"
                  rules="required">
                  <b-form-input
                    id="register-cpf"
                    v-model="register.numberCpfCnpj"
                    v-mask="['###.###.###-##']"
                    name="register-cpf"
                    :state="errors.length > 0 ? false : null"
                    placeholder="000.000.000-00"
                    @blur="verifyRegisterCpfCnpj" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Document -->
              <b-form-group
                v-else
                :label="labelDocumento"
                label-for="register-cnpj">
                <validation-provider
                  #default="{ errors }"
                  name="CNPJ"
                  rules="required">
                  <b-form-input
                    id="register-cnpj"
                    v-model="register.numberCpfCnpj"
                    v-mask="['##.###.###/####-##']"
                    name="register-cnpj"
                    :state="errors.length > 0 ? false : null"
                    placeholder="00.000.000/0000-00"
                    @blur="verifyRegisterCpfCnpj" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="Email" label-for="register-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email">
                  <b-form-input
                    id="register-email"
                    v-model="register.emailAddress"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="seuemail@exemplo.com.br"
                    @blur="verifyRegisterEmail"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Name -->
              <b-form-group :label="labelNome" label-for="register-name">
                <validation-provider
                  #default="{ errors }"
                  name="Nome"
                  rules="required">
                  <b-form-input
                    id="register-name"
                    v-model="register.name"
                    name="register-name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div class="mt-2 button-center-position">
                <b-link class="back" @click="$router.go(-1)"> Voltar </b-link>
              </div>
            </b-form>
          </validation-observer>
        </tab-content>

        <tab-content title="" icon="feather" :before-change="validationForm2">
          <h1 class="divvy-form__title">Faça o seu cadastro</h1>
          <validation-observer ref="registerForm2">
            <b-form class="auth-divvy-form mt-2">
              <!-- Cell -->
              <b-form-group label="Celular" label-for="register-celular">
                <validation-provider
                  #default="{ errors }"
                  name="Celular"
                  rules="required">
                  <b-form-input
                    id="register-celular"
                    v-model="register.phoneNumber"
                    v-mask="['(##) #####-####']"
                    name="register-celular"
                    :state="errors.length > 0 ? false : null"
                    placeholder="(21) 99999-9999" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Birth date -->
              <b-form-group
                v-if="register.typePerson == 'F'"
                label="Data de Nascimento"
                label-for="register-birthDate">
                <validation-provider
                  #default="{ errors }"
                  name="Data de Nascimento"
                  rules="required">
                  <b-form-input
                    id="register-birthDate"
                    v-model="register.birthDate"
                    v-mask="['##/##/####']"
                    name="register-birthDate"
                    :state="errors.length > 0 ? false : null"
                    @change="changeDate('register-birthDate')"
                    placeholder="dd/mm/aaaa" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Type Identification Document pf -->
              <b-form-group
                v-if="register.typePerson === 'F'"
                label="Tipo do Documento de Identificação"
                label-for="register-type-identification-document">
                <validation-provider
                  #default="{ errors }"
                  name="Tipo do Documento de Identificação"
                  rules="required">
                  <b-form-select
                    id="register-type-identification-document-pf"
                    v-model="register.typeDocument"
                    name="register-type-identification-document"
                    :state="errors.length > 0 ? false : null"
                    :options="optionsTypeDocumentPf" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Type Identification Document pj -->
              <b-form-group
                v-else
                label="Tipo do Documento de Identificação"
                label-for="register-type-identification-document">
                <validation-provider
                  #default="{ errors }"
                  name="Tipo do Documento de Identificação"
                  rules="required">
                  <b-form-select
                    id="register-type-identification-document"
                    v-model="register.typeDocument"
                    name="register-type-identification-document"
                    :state="errors.length > 0 ? false : null"
                    :options="optionsTypeDocumentPj" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Identification Document -->
              <b-form-group
                v-if="register.typePerson === 'F'"
                label="Documento de Identificação"
                label-for="register-identification-document">
                <validation-provider
                  #default="{ errors }"
                  name="Documento de Identificação"
                  rules="required">
                  <b-form-input
                    id="register-identification-document"
                    v-model="register.numberRg"
                    name="register-identification-document"
                    :state="errors.length > 0 ? false : null"
                    placeholder="7087222" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-row v-if="isExternal === false">
                <b-col>
                  <b-form-group class="pass" label-for="Senha" label="Senha">
                    <validation-provider
                      #default="{ errors }"
                      name="Senha"
                      rules="required|password">
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null">
                        <b-form-input
                          id="Senha"
                          v-model.lazy="register.password"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false : null"
                          name="Senha"
                          placeholder="············"
                          min="8" />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-card-text>
                    <b-form-group
                      label-for="register-repeat-password"
                      label="Repetir Senha">
                      <validation-provider
                        #default="{ errors }"
                        name="Confirmar senha"
                        rules="required|confirmed:Senha">
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null">
                          <b-form-input
                            id="repeat-password"
                            v-model="register.repeatPassword"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            :state="errors.length > 0 ? false : null"
                            name="repeat-password"
                            placeholder="Confirmar senha" />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIcon"
                              class="cursor-pointer"
                              @click="togglePasswordVisibility" />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-card-text>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </tab-content>
        <tab-content title="" icon="feather" :before-change="validationForm3">          
          <validation-observer ref="registerForm3">
            <h1 class="divvy-form__title">Análise de Dados</h1>

            <p class="divvy-form__analysis-description">
              Iremos analisar os documentos para validar acesso e liberação para
              anunciar na nossa plataforma da melhor forma possível.
            </p>

            <broker-pf v-if="register.typePerson === 'F'" :pf-files-form="register.imgModelDocuments" />
            <broker-pj v-else :pj-files-form="register.imgModelDocuments" />

            <b-form-checkbox
              id="therms-of-use"
              v-model="register.termsOfUse"
              class="divvy-form__analysis-description mt-5"
              name="checkbox-1"
              value="true"
              unchecked-value="false">
              Concordo com os
              <a
                class="term"
                target="_blank"
                rel="noreferer noopener"
                href="/terms"
                >Termos de Uso</a
              >
              e
              <a
                class="term"
                target="_blank"
                rel="noreferer noopener"
                href="/privacy"
                >Política de Privacidade</a
              >
            </b-form-checkbox>

          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-col>
    <b-col class="register-background">
      <div class="register-background-text">
        <h1 class="register-background-text__title">
          Compartilhe imóveis e ganhe comissões rápidas e fáceis
          <span class="register-background-text__title--broker">!</span>
        </h1>
        <p class="register-background-text__paragraph">
          Simples, fácil e seguro para qualquer pessoa investir no mercado
          imobiliário.
        </p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { required, email } from '@validations'
import formatter from '@/mixins/formatter'
import toast from '@/mixins/toast'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BLink,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormCheckbox,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Loader from '@/components/Loader.vue'
import BrokerPj from './components/BrokerPJ.vue'
import BrokerPf from './components/BrokerPF.vue'
import ValidationCpfCnpjService from '@/services/ValidationCpfCnpjService'

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BForm,
    Loader,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormCheckbox,
    FormWizard,
    TabContent,
    ValidationProvider,
    ValidationObserver,
    BrokerPj,
    BrokerPf,
  },
  mixins: [togglePasswordVisibility, formatter, toast],
  
  async mounted() {
    const item = localStorage.getItem('externalLogin')
    if(item != null && Boolean(item)){
      await this.getUserDataExternal()
    }
  },
  
  data: () => ({
    required,
    email,
    loading: false,
    isExternal: false,
    userBrokerProfileId: '',
    passwordFieldTypeOne: 'password',
    passwordFieldTypeTwo: 'password',
    register: {
      typePerson: 'F',
      name: '',
      numberCpfCnpj: '',
      emailAddress: '',
      birthDate: '',
      phoneNumber: '',
      city: '',
      numberRg: '',
      password: '',
      repeatPassword: '',
      typeDocument: '',
      imgModelDocuments: {
        cityOperation: '',
        creci: '',
        imgDocument: '',
        imgFaceDocument: '',
        imgCreci: '',
        imgFaceCreci: '',
      },
      termsOfUse: false,
    },

    labelNome: 'Nome Completo',
    labelDocumento: 'CPF',

    optionsTypeDocumentPf: [
      { value: '1', text: 'RG' },
      { value: '2', text: 'CNH' },
      { value: '3', text: 'Passaporte' },
    ],
    optionsTypeDocumentPj: [{ value: '4', text: 'CNPJ' }],
    selected: '',
    options: [
      { value: 'F', text: 'Pessoa Física' },
      { value: 'J', text: 'Pessoa Jurídica' },
    ],

    path: '',
    warning: '',
    error: '',
  }),
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {

    changeDate(id){
      formatter.validateDate(id, this)
    },

    registerData() {
      const dataBroke = this.treatDataBrokerRegister()
      this.brokerRegisterData(dataBroke)
    },

    async getUserDataExternal() {
      this.isExternal = true
      const model = {
        authProvider: localStorage.getItem('authProvider'),
        providerKey: localStorage.getItem('providerKey'),
        providerAccessCode: localStorage.getItem('providerAccessCode'),
        singleSignIn: false,
      }
      const response =  await this.$http.post(
        '/api/TokenAuth/GetExternalUserInfo',
        model,
        { validateStatus: () => true },
      )
      let result = response.data.result
      this.register.name = result.name + ' ' + result.surname
      this.register.emailAddress = result.emailAddress
    },

    treatDataBrokerRegister() {
      const data = {
        cityOperation: this.register.imgModelDocuments.cityOperation,
        creci: this.register.imgModelDocuments.creci,
        situation: 1,
        termsAccepted: true,
        authProvider: localStorage.getItem('authProvider'),
        providerKey: localStorage.getItem('providerKey'),
        providerAccessCode: localStorage.getItem('providerAccessCode'),
        user: {
          name: this.register.name,
          surname: this.getSurname(this.register.name),
          userName: this.register.emailAddress,
          emailAddress: this.register.emailAddress,
          phoneNumber: this.register.phoneNumber,
          typePerson: this.register.typePerson,
          termsOfUse: this.register.termsOfUse,
          numberCpfCnpj: this.register.numberCpfCnpj.replace(/[^\d]+/g, ''),
          numberRg: this.register.numberRg.replace(/[^\d]+/g, ''),
          birthDate: this.register.birthDate  === '' ? undefined : this.register.birthDate,
          password: this.register.password,
        },
      }

      return data
    },
    async validationForm1() {
      if(this.register.numberCpfCnpj !== '') {
        let result = false
        if(this.register.typePerson === 'F') {
          result = (await ValidationCpfCnpjService.validaCpf(this.register.numberCpfCnpj)).data.result
        } else {
          result = (await ValidationCpfCnpjService.validaCnpj(this.register.numberCpfCnpj)).data.result
        }
        if(result === false) {
          this.error ='Número de CPF/CNPJ inválido'
          this.msgError(this.error)
          return
        }
      }
      return new Promise((resolve, reject) => {
        this.$refs.registerForm1.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    
    async validationForm2() {
      return new Promise((resolve, reject) => {
        this.$refs.registerForm2.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    async validationForm3() {
      return new Promise((resolve, reject) => {
        this.$refs.registerForm3.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    treatFileBrokerRegister() {
      const imgLoop = {
        documentFile: {
          bytes: this.register.imgModelDocuments.imgDocument.split(',')[1],
          description: null,
          dataType: this.register.imgModelDocuments.imgDocument.split(',')[0],
        },
        facePhotoDocumentFile: {
          bytes: this.register.imgModelDocuments.imgFaceDocument.split(',')[1],
          description: null,
          dataType: this.register.imgModelDocuments.imgFaceDocument.split(',')[0],
        },
        creciFile: {
          bytes: this.register.imgModelDocuments.imgCreci.split(',')[1],
          description: null,
          dataType: this.register.imgModelDocuments.imgCreci.split(',')[0],
        },
        facePhotoCreciFile: {
          bytes: this.register.imgModelDocuments.imgFaceCreci.split(',')[1],
          description: null,
          dataType: this.register.imgModelDocuments.imgFaceCreci.split(',')[0],
        },
        userBrokerProfileId: this.userBrokerProfileId,
      }

      return imgLoop
    },

    async brokerRegisterData(data) {
      try {
        if(this.register.birthDate === '') {
          this.register.birthDate = undefined
        }
        this.loading = true
        let endpoint = '/api/services/app/BrokerPublicService/CreateBroker'
        if(this.isExternal) {
          endpoint = '/api/services/app/BrokerPublicService/CreateBrokerExternal'
          data.user.password = '12345678910'
        }
        await this.$http
          .post(endpoint, data, {
            withCredentials: false,
            timeout: 30000,
          })
          .then(response => {
            this.userBrokerProfileId = response.data.result
            const fileBroke = this.treatFileBrokerRegister()
            this.brokerRegisterFiles(fileBroke)
            this.msgSuccess()
            setTimeout(() => {
              this.$router.push({ name: 'broker-registered' })
            }, 2700)
          })
          .catch(error => {
            this.error = error.message
          this.msgError(this.error)
          })
        this.loading = false
      } catch (error) {
        this.loading = false
        this.error = error.message
          this.msgError(this.error)
      }
    },
    async brokerRegisterFiles(data) {
      try {
        await this.$http
          .post('/api/services/app/BrokerPublicService/SendFilesBroker', data, {
            withCredentials: false,
            timeout: 30000,
          })
          .then(response => response.status)
          .catch(error => {
            this.errors = error.message
          })
      } catch (error) {
        this.errors = error.message
      }
    },

    async verifyRegisterCpfCnpj(e) {
      const document = e.target.value.replace(/[^\d]+/g, '')
      const response = await this.$http.get(
        `/api/services/app/BrokerPublicService/FindBrokerExistingDocument?search=${document}`,
      )
      if (response.data.result.statusCode === 200) {
        // this.$router.push('/broker/registered/true')
        this.path = 'broker-login'
        this.warning = 'Caro usuário, você já possui um cadastro como corretor, favor efetuar o seu login.'
        this.msgWarning()
      } else if (response.data.result.statusCode === 303) {
        this.path = 'broker-login'
        this.warning = 'Caro usuário, nossa equipe técnica já recebeu o seu cadastro, aguarde o email para os próximos passos, obrigado.'
        this.msgWarningInactive()
      }
    },
    async verifyRegisterEmail(e) {
      const response = await this.$http.get(
        `/api/services/app/BrokerPublicService/FindBrokerExistingEmail?search=${e.target.value}`,
      )

      if (response.data.result.statusCode === 200) {
        // this.$router.push('/broker/registered/true')
        this.path = 'broker-login'
        this.warning = 'Caro usuário, você já possui um cadastro como corretor, favor efetuar o seu login.'
        this.msgWarning()
      } else if (response.data.result.statusCode === 303) {
        this.path = 'broker-login'
        this.warning = 'Caro usuário, nossa equipe técnica já recebeu o seu cadastro, aguarde o email para os próximos passos, obrigado.'
        this.msgWarningInactive()
      }
    },

    submitForm() {
      this.$refs.registerForm3.validate().then(success => {
        if (success) {
          const ff = this.register.termsOfUse
          if(this.register.termsOfUse === "true") {
            this.registerData()
          } else {
            this.error = 'Você precisa aceitar os termos'
          this.msgError(this.error)
          }
        }
      })
    },
    changeLabel() {
      if (this.register.typePerson === 'F') {
        this.labelNome = 'Nome Completo'
        this.labelDocumento = 'CPF'
      }
      if (this.register.typePerson === 'J') {
        this.labelNome = 'Razão Social'
        this.labelDocumento = 'CNPJ'
      }
    },
    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Sua conta foi registrada com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // // error
    // msgError() {
    //   this.$swal({
    //     icon: 'error',
    //     title: 'Error ao registrar conta',
    //     customClass: {
    //       confirmButton: 'btn btn-primary',
    //     },
    //     buttonsStyling: false,
    //     html: `${this.error}
    //         <br> <br> <br>
    //         <button type="button" variant="success" class="btn btn-success btn-yes swl-cstm-btn-yes-sbmt-rqst">Login</button>
    //         <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
    //     showCancelButton: false,
    //     showConfirmButton: false,
    //     onBeforeOpen: () => {
    //       const yes = document.querySelector('.btn-yes')
    //       const no = document.querySelector('.btn-no')

    //       yes.addEventListener('click', () => {
    //         this.$swal.close()
    //         this.$router.push({ name: 'login' })
    //       })

    //       no.addEventListener('click', () => {
    //         this.$swal.close()
    //       })
    //     },
    //   })
    // },

    // warning
    msgWarning() {
      this.$swal({
        icon: 'warning',
        title: 'Atenção',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        html: `${this.warning}
            <br> <br> <br>
            <button type="button" variant="primary" class="btn color-button-broker btn-primary btn-yes swl-cstm-btn-yes-sbmt-rqst">Login</button>
            <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
        showCancelButton: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          const yes = document.querySelector('.btn-yes')
          const no = document.querySelector('.btn-no')

          yes.addEventListener('click', () => {
            this.$swal.close()
            this.$router.push({ name: this.path })
          })

          no.addEventListener('click', () => {
            this.$swal.close()
          })
        },
      })
    },

    // warning
    msgWarningInactive() {
      this.$swal({
        icon: 'warning',
        title: 'Atenção',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        html: `${this.warning}
            <br> <br> <br>
            <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
        showCancelButton: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          const no = document.querySelector('.btn-no')

          no.addEventListener('click', () => {
            this.$swal.close()
            this.$router.push({ name: this.path })
          })
        },
      })
    },
    getSurname(str) {
      const textReplace = ' '
      const res = str.substring(str.indexOf(textReplace) + textReplace.length)
      return res
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
@import '../sass/variables.scss';
@import '../sass/divvy-form.scss';
@import '../sass/vertical-form-wizard.scss';
@import '../sass/register-background-text.scss';
@import '../sass/broker.scss';
a.back {
  color: #000;
  font-weight: 500;
}
a.term {
  color: #c779f7;
}
.register-background {
  display: none;

  @media (min-width: 992px) {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-image: url('~@/assets/images/pages/background_broker.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  box-shadow: none !important;
}
.button-center-position {
  position: absolute;
  margin-top: 85px !important;
  left: 47.3%;
}
.input-group {
  &:not(.bootstrap-touchspin):focus-within {
    .color-input-group > div.input-group-text {
      border-color: #bc7df0 !important;
    }
  }
}
.broker .is-invalid {
  &:not(.bootstrap-touchspin):focus-within {
    .input-group-append > div.input-group-text {
      border-color: #ea5455 !important;
    }
  }
}
</style>
