// We haven't added icon's computed property because it makes this mixin coupled with UI
export const togglePasswordVisibility = {
  data() {
    return {
      passwordFieldType: 'password',
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
  },
}
export const togglePasswordVisibilityOne = {
  data() {
    return {
      passwordFieldTypeOne: 'password',
    }
  },
  methods: {
    togglePasswordVisibilityOne() {
      this.passwordFieldTypeOne = this.passwordFieldTypeOne === 'password' ? 'text' : 'password'
    },
  },
}
export const togglePasswordVisibilityTwo = {
  data() {
    return {
      passwordFieldTypeTwo: 'password',
    }
  },
  methods: {
    togglePasswordVisibilityTwo() {
      this.passwordFieldTypeTwo = this.passwordFieldTypeTwo === 'password' ? 'text' : 'password'
    },
  },
}

export const _ = null
